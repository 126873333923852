import React, { useEffect, useState, state } from 'react'
import { GET_ALL_ORDER_KITCHEN, UPDATE_BILL_DETAIL } from 'utils/constants/api'
import useFetch from 'utils/hooks/useFetch';
import moment from 'moment';
import { data } from 'autoprefixer';
import { io } from "socket.io-client";
import useSound from 'use-sound';
import boopSfx from 'sounds/boop.mp3';
const socket = io(process.env.REACT_APP_SOCKET_URL);

function OrderList() {
    const [play] = useSound(boopSfx);
    const { data: order, loading: loadingOrder, fetch: getOrderKitchen } = useFetch(GET_ALL_ORDER_KITCHEN)
    const { fetch: updateBill } = useFetch(UPDATE_BILL_DETAIL)

    function customSort(a, b) {
        const processedA = a.orders.some(order => order.process_status === 'NEW_ORDER');
        const processedB = b.orders.some(order => order.process_status === 'NEW_ORDER');

        if (processedA && !processedB) {
            return -1; // Put table A first
        } else if (!processedA && processedB) {
            return 1; // Put table B first
        } else {
            return moment(a.table_dt) - moment(b.table_dt)
        }
    }

    const ordersSorter = (a, b) => {
        const processedA = a.process_status === 'NEW_ORDER'
        const processedB = b.process_status === 'NEW_ORDER'

        if (processedA && !processedB) {
            return -1;
        } else if (!processedA && processedB) {
            return 1;
        } else {
            return 0
        }
    }

    useEffect(() => {
        getOrderKitchen({
            data: {}
        })
    }, [getOrderKitchen])

    useEffect(() => {
        socket.on("new-order", (data) => getOrderKitchen({
            data: {}
        }).then(() => { play() })

        )
        return () => socket.off("new-order")
    }, [getOrderKitchen, play])

    if (loadingOrder) {
        return (
            <section className='app-kitchen-container font-semibold text-2xl'>
                Loading...
            </section>
        )
    }

    return (
        <div>
            <section className='app-kitchen-container'>
                <p className='font-semibold text-3xl'>Order List</p>

                <div className='grid grid-cols-3 gap-4 py-4'>
                    {order?.data?.sort(customSort)?.map((item, i) => (
                        <div className='border border-app-light-grey px-5 py-4'>
                            <div className='flex justify-between  py-4'>
                                <span className='font-bold text-xl'>{item.table_nm}</span>
                                <span className='text-right text-xl'>{moment(item.table_dt).format('HH:mm')}</span>
                            </div>
                            <table className="table-fixed text-sm w-full border-b border-app-matte-black">
                                <thead>
                                    <tr>
                                        <th className="text-left px-1 py-2 border-b border-app-matte-black">Name</th>
                                        <th className="text-center px-1 py-2 border-b border-app-matte-black w-12">Qty</th>
                                        <th className="text-center px-1 py-2 border-b border-app-matte-black w-28">Order Time</th>
                                        <th className="text-center px-1 py-2 border-b border-app-matte-black w-12">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {item?.orders?.sort(ordersSorter)?.map((orderDetail, x) => (
                                        <tr className={orderDetail?.process_status?.toUpperCase() === "DONE" ? "line-through opacity-50" : ""}>
                                            <td className="text-left px-1 py-2" htmlFor='checkBoxes'>
                                                <p className="font-medium">{orderDetail?.menu_nm}</p>
                                                {orderDetail?.bill_dtl_desc && (
                                                    <p className='text-sm'>Notes: <span className="text-app-grey">{orderDetail.bill_dtl_desc}</span></p>
                                                )}
                                            </td>
                                            <td className="text-center px-1 py-2 w-12" htmlFor='checkBoxes'>{orderDetail.bill_dtl_qty}</td>
                                            <td className="text-center px-1 py-2 w-28" htmlFor='checkBoxes'>{moment(orderDetail.bill_dtl_dt).format('HH:mm')}</td>
                                            <td className="text-center px-1 py-2 w-12">
                                                <input
                                                    disabled={orderDetail?.process_status?.toUpperCase() === "DONE"}
                                                    id='checkBoxes'
                                                    type="checkbox"
                                                    onClick={() => {
                                                        updateBill({ data: { cd: orderDetail.bill_dtl_cd, process_status: "DONE" } })
                                                            .then(response => {
                                                                window.location.reload(false);
                                                            })
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    )
}
export default OrderList